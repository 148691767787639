var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "multiple-form-items" },
    _vm._l(_vm.computedItems, function (item, i) {
      return _c(
        "el-form-item",
        {
          key: i,
          attrs: {
            label: _vm.label,
            prop: `${_vm.field}.${i}`,
            rules: _vm.getRules,
          },
        },
        [
          _c("div", { staticClass: "multiple-form-items__view" }, [
            !_vm.isMobile
              ? _c(
                  "div",
                  { staticClass: "multiple-form__desktopView" },
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", align: "middle" } },
                      [
                        _vm.getFiltredList(i).length > 0
                          ? _c("IqSelect", {
                              attrs: {
                                options: _vm.getFiltredList(i),
                                placeholder: _vm.placeholder,
                                size: "large",
                                disabled: _vm.disabled,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChange($event, i)
                                },
                              },
                              model: {
                                value: _vm.computedItems[i],
                                callback: function ($$v) {
                                  _vm.$set(_vm.computedItems, i, $$v)
                                },
                                expression: "computedItems[i]",
                              },
                            })
                          : _vm._e(),
                        _vm.canAdd &&
                        !_vm.disabled &&
                        _vm.list.length - 1 !== i &&
                        (_vm.computedItems.length === 1 ||
                          (_vm.computedItems.length > 1 &&
                            _vm.computedItems.length - 1 === i))
                          ? _c("i", {
                              staticClass:
                                "el-icon-circle-plus multiple-form-items__icon multiple-form-items__add",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("action", -1, _vm.field)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.canAdd &&
                        !_vm.disabled &&
                        _vm.computedItems.length > 1 &&
                        _vm.computedItems.length - 1 === i
                          ? _c("i", {
                              staticClass:
                                "el-icon-error multiple-form-items__icon multiple-form-items__remove",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("action", i, _vm.field)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "multiple-form__mobileView" }, [
                  _c("div", { staticClass: "mobileView-selectRow" }, [
                    _c(
                      "div",
                      { staticClass: "mobileView-selectRow__select" },
                      [
                        _vm.getFiltredList(i).length > 0
                          ? _c("IqSelect", {
                              attrs: {
                                options: _vm.getFiltredList(i),
                                placeholder: _vm.placeholder,
                                size: "large",
                                disabled: _vm.disabled,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChange($event, i)
                                },
                              },
                              model: {
                                value: _vm.computedItems[i],
                                callback: function ($$v) {
                                  _vm.$set(_vm.computedItems, i, $$v)
                                },
                                expression: "computedItems[i]",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mobileView-selectRow__removeButton" },
                      [
                        _vm.canAdd &&
                        !_vm.disabled &&
                        _vm.computedItems.length > 1 &&
                        _vm.computedItems.length - 1 === i
                          ? _c("CloseIcon", {
                              on: {
                                "icon-clicked": function ($event) {
                                  return _vm.$emit("action", i, _vm.field)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "mobileView-addButton" }, [
                    !_vm.disabled &&
                    _vm.list.length - 1 !== i &&
                    (_vm.computedItems.length === 1 ||
                      (_vm.computedItems.length > 1 &&
                        _vm.computedItems.length - 1 === i))
                      ? _c(
                          "i",
                          {
                            staticClass:
                              "el-icon-circle-plus multiple-form-items__icon multiple-form-items__add",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("action", -1, _vm.field)
                              },
                            },
                          },
                          [_c("span", [_vm._v("Добавить")])]
                        )
                      : _vm._e(),
                  ]),
                ]),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }